import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link } from 'gatsby';

import FooterColumn from './FooterColumn';

import './Footer.css';
import iconFB from '../../../images/icon-fb.png';
import iconIN from '../../../images/icon-in.png';
import fe2020 from '../../../images/press/fe-2020.png';
import sting from '../../../images/press/sting.png';
import StoreLogo from '../StoreLogo';

const TextLink = ({ children, to }) => (
  <p>
    <Link to={to}>{children}</Link>
  </p>
);

export default () => (
  <footer className="footer mt-auto py-3">
    <Container className="footer-rows">
      <Row className="footer-row">
        <FooterColumn title="Lär känna oss">
          <TextLink to="/about">Om oss</TextLink>
          <TextLink to="/press">Press</TextLink>
          <TextLink to="/jobs">Karriär</TextLink>
          <TextLink to="/terms-and-conditions">Allmänna villkor</TextLink>
          <TextLink to="/cookies">Kakor</TextLink>
          <TextLink to="/faq">Vanliga frågor</TextLink>
          {/*<TextLink to="/privacy-policy">Integritetspolicy</TextLink>*/}
        </FooterColumn>

        <FooterColumn title="Kom igång">
          <TextLink to="/signup">Skapa konto</TextLink>
          <TextLink to="/signup">Lös in kampanjkod</TextLink>
          <p className="stores">
            <StoreLogo trackerName="footer" type={StoreLogo.TYPES.APPLE} />
            <StoreLogo trackerName="footer" type={StoreLogo.TYPES.GOOGLE} />
          </p>
        </FooterColumn>

        <FooterColumn title="Följ oss">
          <p>
            <a href="https://facebook.com/boksnok.se" target="_blank" rel="noopener noreferrer">
              <img alt="Facebook logo" className="social-logo" src={iconFB} />
              Facebook
            </a>
          </p>
          <p>
            <a href="https://www.instagram.com/boksnok/" target="_blank" rel="noopener noreferrer">
              <img alt="Instagram logo" className="social-logo" src={iconIN} />
              Instagram
            </a>
          </p>
          <p>
            &nbsp;
            <a href="mailto:hej@boksnok.se" rel="noopener noreferrer" target="_blank">
              <span className="no-hover">@&nbsp;&nbsp;</span>Maila oss
            </a>
          </p>
        </FooterColumn>

        {/*<FooterColumn title="Kundservice">*/}
        {/*  <TextLink to="/contact-us">Kontakta oss</TextLink>*/}
        {/*</FooterColumn>*/}
      </Row>
      <Row className="footer-row logo">
        <Col>
          <a
            href="https://www.svd.se/smarta-barnbocker-segrar--ska-na-miljoner"
            rel="noopener noreferrer"
            target="_blank"
            title="Boksnok utses till Framtidens entreprenör 2020"
          >
            <img alt="Framtidens entreprenör 2020 emblem" src={fe2020} width="100" />
          </a>
        </Col>
        <Col>
          <a
            href="https://sting.co/companies/boksnok"
            rel="noopener noreferrer"
            target="_blank"
            title="Boksnok utvald till Sting Accelerate 2020"
          >
            <img alt="Sting logo" src={sting} width="200" />
          </a>
        </Col>
      </Row>
    </Container>
  </footer>
);

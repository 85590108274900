import React from 'react';
import PropTypes from 'prop-types';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import { connect } from 'react-redux';

import LoggedInNavItem from './LoggedInNavItem';
import LoggedOutNavItem from './LoggedOutNavItem';
import NavLink from './NavLink';

import logoWhiteSvg from '../../../images/logo-white.svg';
import snoken from '../../../images/snoken-x.png';

import ProfileHead from './ProfileHead';
import NavButton from './NavButton';

import './Navbar.css';

const MyNavbar = ({
  ctaText,
  ctaUrl,
  headerColor,
  hideLogo,
  hideSnoken,
  userIsLoaded,
  userIsLoggedIn,
}) => (
  <Navbar
    variant="dark"
    className={`boksnok-navbar${headerColor ? ` bg-${headerColor}` : ''}`}
    collapseOnSelect
    fixed="top"
    expand="md"
  >
    <Container>
      <Navbar.Brand className={`${hideLogo ? 'hide' : ''}`}>
        <NavLink to="/" noPadding>
          <img alt="Boksnoks logo" src={logoWhiteSvg} className="logo logo-in-text" />
          {!hideSnoken && <img alt="Boksnok" src={snoken} className="logo logo-snok" />}
        </NavLink>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto" />
        <Nav>
          <NavLink className="hidden-down-to-md" to="/signup">
            Prova Boksnok
          </NavLink>
          <NavLink to="/books">Sök titlar</NavLink>
          <NavLink to="/giftcard">Presentkort</NavLink>
          {userIsLoaded ? (
            userIsLoggedIn ? (
              <LoggedInNavItem />
            ) : (
              <LoggedOutNavItem />
            )
          ) : (
            <Nav.Item>
              <ProfileHead standalone />
            </Nav.Item>
          )}
          <NavButton className="hidden-up-to-sm" to={ctaUrl || '/signup'}>
            {ctaText || 'Prova Boksnok'}
          </NavButton>
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
);

MyNavbar.propTypes = {
  ctaText: PropTypes.string,
  ctaUrl: PropTypes.string,
  headerColor: PropTypes.string.isRequired,
  hideLogo: PropTypes.bool,
  hideSnoken: PropTypes.bool,
  userInfo: PropTypes.object,
};

MyNavbar.defaultProps = {
  hideSnoken: true,
};

export default connect(({ user: { userIsLoaded, userIsLoggedIn } }) => ({
  userIsLoaded,
  userIsLoggedIn,
}))(MyNavbar);

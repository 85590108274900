import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { BoksnokModal } from './';
import StoreLogo from '../StoreLogo';

const BookPromoModel = ({ cover, size, show, title, userIsLoggedIn, xsFullWidth, onClose }) => {
  return (
    <BoksnokModal show={show} title={title} onClose={onClose}>
      {/* eslint-disable */}
      <img
        alt={`Bokomslag${title ? `för '${title}'` : ''}`}
        className={`cover ${size} ${xsFullWidth ? ' xs-full-width' : ''}`}
        src={cover}
        onClick={onClose}
      />
      {/* eslint-enable */}
      <p className="dialog-header">
        {userIsLoggedIn ? <>Läs boken i Boksnok-appen</> : <>Ladda hem Boksnok</>}
      </p>
      <p style={{ textAlign: 'center' }}>
        Vad skoj att du vill läsa <i>{title}</i>!<br />
        {userIsLoggedIn ? (
          <>Den kan du läsa i Boksnok-appen.</>
        ) : (
          <>Då behöver du först fixa ett konto, och du, det är gratis&nbsp;i&nbsp;14&nbsp;dagar.</>
        )}
      </p>
      {!userIsLoggedIn && (
        <p style={{ fontWeight: 'bold', textAlign: 'center' }}>Du behöver inget betalkort!</p>
      )}
      <p style={{ margin: '0 1rem 0 0', textAlign: 'center' }}>
        <StoreLogo type={StoreLogo.TYPES.APPLE} trackerName="book-modal" className="app-logo" />
        <StoreLogo type={StoreLogo.TYPES.GOOGLE} trackerName="book-modal" className="app-logo" />
      </p>
    </BoksnokModal>
  );
};

BookPromoModel.propTypes = {
  cover: PropTypes.string.isRequired,
  show: PropTypes.bool,
  size: PropTypes.oneOf(['md', 'sm']),
  title: PropTypes.string,
  xsFullWidth: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

BookPromoModel.defaultProps = {
  show: true,
  xsFullWidth: false,
};

export default connect(({ user: { userIsLoggedIn } }) => ({ userIsLoggedIn }))(BookPromoModel);

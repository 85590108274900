import { defaultGiftcardProducts } from './config';
import { PAYMENT_TYPES } from '../constants/index';
import { sleepRnd } from './utils';

const { API_HOST, PAYMENT_API_HOST, STRIPE_IS_TEST } = require('../lib/config');

const extendOptions = (opt, skipContentType = false) => ({
  mode: 'cors', // no-cors, *cors, same-origin
  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
  credentials: 'omit',
  ...(skipContentType
    ? {}
    : {
        headers: {
          'Content-Type': 'application/json',
        },
      }),
  // redirect: 'follow', // manual, *follow, error
  // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  ...opt,
});

const commonDataResponse = async (res) => {
  const { status, headers } = res;
  const contentType = headers.get('Content-Type') || '';
  const isJson = contentType.startsWith('application/json');

  if (status >= 400) {
    let err;

    if (isJson) {
      const errJson = await res.json();
      err = new Error(errJson.message);
      console.log(errJson);
      err.fields = errJson.errors.reduce(
        (fields, { field, error }) => ({
          ...fields,
          [field]: error,
        }),
        {}
      );
    } else {
      const errText = await res.text();
      err = new Error(errText);
      err.fields = {};
    }
    err.statusCode = res.status;

    throw err;
  }

  return isJson ? res.json() : res.text();
};
const toUrl = (path) => `${path.startsWith('/') ? API_HOST : ''}${path}`;

async function del(path) {
  return fetch(toUrl(path), extendOptions({ method: 'DELETE' }, true)).then(commonDataResponse);
}
async function get(path, skipContentType) {
  return fetch(toUrl(path), extendOptions({ method: 'GET' }, skipContentType)).then(
    commonDataResponse
  );
}
async function post(path, data = {}) {
  return fetch(toUrl(path), extendOptions({ method: 'POST', body: JSON.stringify(data) })).then(
    commonDataResponse
  );
}
async function put(path, data = {}) {
  return fetch(toUrl(path), extendOptions({ method: 'PUT', body: JSON.stringify(data) })).then(
    commonDataResponse
  );
}

function toObject(data) {
  if (typeof data === 'string') {
    try {
      return JSON.parse(data);
    } catch (_) {}
  }
  return data;
}

function toCampaignObject(data) {
  const { trialDuration, ...obj } = toObject(data);
  if (trialDuration) {
    const days = Math.round(trialDuration / (24 * 3600 * 1000));
    // Todo: Handle giftcard as well ("type": 'campaign' / 'giftCard', "unit" = 'day' / 'month')
    return {
      ...obj,
      type: 'campaign',
      unit: 'day',
      duration: days,
    };
  }

  return obj;
}

const mockGiftcards = async () => {
  await sleepRnd(200, 1200);

  return defaultGiftcardProducts;
};

export const getCampaignCode = (code) =>
  get(`/v2/codes/${code.toLowerCase()}`).then(toCampaignObject);
export const createAccount = (data) => post(`/v2/user/create`, data).then(toObject);
export const createStripeAccount = (data) => post(`/v2/users/stripe`, data).then(toObject);
export const requestPasswordReset = (email) => get(`/v2/web/resetPassword/${email}`, true);
export const resetPassword = (password, token) =>
  post(`/v2/web/resetPassword`, { password, token });
export const login = async (data) => {
  const { _id: sessionId } = await post(`/v2/web/login`, data);
  const userData = await me(sessionId);
  return {
    sessionId,
    userData,
  };
};

export const loginWithToken = (token) =>
  post('/v2/web/tokenLogin', { loginToken: token }).then(toObject);

export const logout = (sessionId) => post(`/v2/web/logout?sessionId=${sessionId}`);
export const me = (sessionId) => get(`/v2/web/me?sessionId=${sessionId}`);
export const getPaymentInfo = (userId, sessionId) =>
  get(`/v2/web/users/${userId}/subscription?sessionId=${sessionId}`);
export const cancelSubscription = async (userId, reason, sessionId, paymentType) => {
  if (paymentType === PAYMENT_TYPES.STRIPE) {
    const res = await del(`/v2/subscriptions/stripe?sessionId=${sessionId}`);
    await sendUserFeedback(reason, sessionId);
    return res;
  } else if (paymentType === PAYMENT_TYPES.MONDIDO) {
    const res = await del(`/v2/web/users/${userId}/subscription?sessionId=${sessionId}`);
    await sendUserFeedback(reason, sessionId);
    return res;
  }
};
export const sendUserFeedback = async (reason, sessionId) =>
  post(`/v2/web/users/feedback?sessionId=${sessionId}`, { reason });
export const requestNewsletter = (email) => post('/v2/web/newsletter', { email });
export const updateEmail = (email, sessionId) =>
  put(`/v2/web/email?sessionId=${sessionId}`, { email });
export const updatePassword = (oldP, newP, sessionId) =>
  put(`/v2/web/password?sessionId=${sessionId}`, { password: { old: oldP, new: newP } });

export const getBooks = (offset, limit, search) =>
  get(`/v2/web/books?offset=${offset}&limit=${limit}${search ? `&query=${search}` : ''}`);
export const getPopularBooks = () => get(`/v2/web/books/popular`);
export const getPopularTags = () => get(`/v2/web/tags/popular`);

export const getGiftcards = () => get(`/v2/giftcards`).catch(mockGiftcards);
export const buyGiftcard = () => {
  const url = `${PAYMENT_API_HOST}${STRIPE_IS_TEST ? '/dev' : ''}/charges`;
  return post(url);
};

export const createStripeSubscription = (paymentMethodId, sessionId) =>
  post(`/v2/subscriptions/stripe?sessionId=${sessionId}`, { paymentMethodId });

export const stripeSubscriptionComplete = (sessionId) =>
  put(`/v2/users/stripe?sessionId=${sessionId}`, {});

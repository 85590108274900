import { setSessionId, removeSessionId } from '../lib/storage';

import { USER } from './actionTypes';

export const userNotLoggedIn = () => ({
  type: USER.LOADED,
});

export const userLoggedIn = (sessionId, userData) => {
  setSessionId(sessionId);
  return {
    type: USER.LOGGED_IN,
    payload: userData,
  };
};

export const userLoggedOut = () => {
  removeSessionId();
  return {
    type: USER.LOGGED_OUT,
    payload: null,
  };
};

export const userDataLoaded = (userData) => ({
  type: USER.DATA_LOADED,
  payload: userData,
});

export const userEmailUpdated = (email) => ({
  type: USER.EMAIL_UPDATED,
  payload: email,
});

export const userPaymentInfoLoaded = (paymentInfo) => ({
  type: USER.PAYMENT_INFO_LOADED,
  payload: paymentInfo,
});

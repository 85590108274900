import React from 'react';
import PropTypes from 'prop-types';

import appStore from '../../images/appstore.png';
import googlePlay from '../../images/googleplay.png';

const CONFIG = {
  APPLE: {
    alt: 'App Store logo',
    href: 'https://itunes.apple.com/se/app/id1036395238',
    imageIdSuffix: '-app-store-image',
    linkIdSuffix: '-app-store-link',
    src: appStore,
  },
  GOOGLE: {
    alt: 'Google Play logo',
    href: 'https://play.google.com/store/apps/details?id=se.boksnok.android',
    imageIdSuffix: '-google-play-image',
    linkIdSuffix: '-google-play-link',
    src: googlePlay,
  },
};

const StoreLogo = ({ className, trackerName, type }) => {
  return (
    <a
      id={`${trackerName}${CONFIG[type].linkIdSuffix}`}
      href={CONFIG[type].href}
      rel="noopener noreferrer"
      target="_blank"
    >
      <img
        alt={CONFIG[type].alt}
        className={className}
        id={`${trackerName}${CONFIG[type].imageIdSuffix}`}
        src={CONFIG[type].src}
      />
    </a>
  );
};

StoreLogo.TYPES = {
  APPLE: 'APPLE',
  GOOGLE: 'GOOGLE',
};

StoreLogo.propTypes = {
  className: PropTypes.string,
  trackerName: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.keys(CONFIG)).isRequired,
};

export default StoreLogo;

import PropTypes from 'prop-types';
import React from 'react';

import soundIcon from '../../images/icons/sound.png';

import './BookDetails.css';
import { Button } from './index';

const Book = ({ ageGroup, author, cover, pages, publisher, sound, summary, title }) => {
  return (
    <div className="book-details">
      <div className="cover-wrapper">
        <img alt={`Bokomslag för '${title}'`} className={`cover`} src={cover} />
        {sound && (
          <img
            alt="Denna bok har uppläsning"
            className="sound"
            src={soundIcon}
            title="Denna bok har uppläsning"
          />
        )}
      </div>

      <div className="book-meta">
        <p className="credits">{author}</p>
        <p className="publisher">{publisher}</p>
        <p>&nbsp;</p>
        <p className="age">{ageGroup} år</p>
        <p className="pages">{pages} sidor</p>
      </div>

      <h2 className="book-title">{title}</h2>
      <p className="book-summary">{summary}</p>

      <div className="book-actions">
        <Button small bg="purple" to="/signup">
          Jag vill läsa nu!
        </Button>
        <Button small secondary bg="purple" to="/books">
          Fortsätt utforska
        </Button>
      </div>
    </div>
  );
};

Book.propTypes = {
  ageGroup: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  cover: PropTypes.string.isRequired,
  illustrator: PropTypes.string.isRequired,
  pages: PropTypes.number.isRequired,
  publisher: PropTypes.string.isRequired,
  sound: PropTypes.bool,
  summary: PropTypes.string.isRequired,
  title: PropTypes.string,
};
Book.defaultProps = {
  sound: false,
};

export default Book;

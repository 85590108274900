/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { useLocation } from '@reach/router';

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl
      }
    }
  }
`;

function SEO({ description, lang, meta, title, ogImage, ogTitle, redirect, addPath }) {
  const { pathname, search } = useLocation();
  const { site } = useStaticQuery(query);

  const { defaultTitle, defaultDescription, siteUrl } = site.siteMetadata;

  const metaDescription = description || defaultDescription;

  React.useEffect(() => {
    if (redirect) {
      window.location.replace(`${redirect}${addPath ? pathname : ''}${search}`);
    }
  }, []);

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${defaultTitle}`}
      meta={[
        { name: `description`, content: `${metaDescription}` },
        { property: `og:title`, content: ogTitle || defaultTitle },
        { property: `og:description`, content: metaDescription },
        { property: `og:type`, content: `website` },
        { property: 'og:url', content: `${siteUrl}${pathname}` },
        { property: 'og:site_name', content: 'Boksnok' },
        { property: 'og:locale', content: 'sv_SE' },
        { property: 'og:image', content: ogImage.url },
        { property: 'og:image:secure_url', content: ogImage.url },
        { property: 'og:image:type', content: `image/${ogImage.type}` },
        { property: 'og:image:width', content: `${ogImage.width}` },
        { property: 'og:image:height', content: `${ogImage.height}` },
        { name: `twitter:card`, content: `summary` },
        { name: `twitter:title`, content: 'Boksnok' },
        { name: `twitter:description`, content: metaDescription },
        // {
        //   name: `twitter:creator`,
        //   content: site.siteMetadata.author,
        // },
      ].concat(meta)}
    >
      <link rel="preconnect" href="https://fonts.gstatic.com/" crossOrigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,400;1,400;1,700&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Fredoka+One:ital,wght@0,400;1,400;1,700&display=swap"
        rel="stylesheet"
      />
    </Helmet>
  );
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  ogImage: PropTypes.shape({
    height: PropTypes.number.isRequired,
    type: PropTypes.oneOf(['gif', 'jpeg', 'png']).isRequired,
    url: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
  }),
  ogTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

SEO.defaultProps = {
  description: ``,
  lang: `sv`,
  meta: [],
  ogImage: {
    height: 1200,
    type: 'jpeg',
    // Image needs to be uploaded manually to FTP!
    url: 'https://boksnok.se/using-boksnok-2-wide.jpg',
    width: 1200,
  },
  ogTitle: 'Boksnok',
};

export default SEO;

import React from 'react';
import loggedInIcon from '../../../images/icons/loggedin-white.png';

const ProfileHead = ({ big = false, standalone = false }) => {
  const img = <img src={loggedInIcon} width={big ? '20' : '14'} alt="Min profil" />;
  if (standalone) {
    return <div style={{ padding: '0.5em', verticalAlign: 'middle' }}>{img}</div>;
  }
  return <>{img}</>;
};

export default ProfileHead;

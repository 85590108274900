import React from 'react';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'gatsby';

const NavLink = ({ children, className, noPadding, to }) => (
  <Nav.Item>
    <Link to={to} className={`nav-link ${noPadding || ''} ${className || ''}`}>
      {children}
    </Link>
  </Nav.Item>
);

export default NavLink;

import PropTypes from 'prop-types';
import React, { useState } from 'react';

import './Book.css';
import { BookPromoModel } from './modals';

import soundIcon from '../../images/icons/sound.png';

const Book = ({ cover, size, showTitle, sound, title, xsFullWidth }) => {
  const [showInfo, setShowInfo] = useState(false);
  const handleShowInfo = () => {
    setShowInfo(true);
  };
  const handleHideInfo = () => {
    setShowInfo(false);
  };

  return (
    <div className={`book ${size}`}>
      {/* eslint-disable-next-line */}
      {sound && <img className="sound" src={soundIcon} />}
      {/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
      <img
        alt={`Bokomslag${title ? `för '${title}'` : ''}`}
        className={`cover ${size} ${xsFullWidth ? ' xs-full-width' : ''}`}
        src={cover}
        onClick={handleShowInfo}
      />
      {/* eslint-enable */}
      {title && showTitle && (
        <div className="child">
          <p title={title}>{title}</p>
        </div>
      )}

      <BookPromoModel
        {...{ cover, size, title, xsFullWidth }}
        show={showInfo}
        onClose={handleHideInfo}
      />
    </div>
  );
};

Book.propTypes = {
  cover: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['md', 'sm']),
  title: PropTypes.string,
  showTitle: PropTypes.bool,
  xsFullWidth: PropTypes.bool,
};
Book.defaultProps = {
  size: 'md',
  sound: false,
  showTitle: true,
  xsFullWidth: false,
};

export default Book;

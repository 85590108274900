import React from 'react';
import Nav from 'react-bootstrap/Nav';

import './NavButton.css';
import { Button } from '../';

const NavButton = ({ children, className, to }) => (
  <Nav.Item>
    <Button className={`nav-link nav-button ${className || ''}`} small to={to}>
      {children}
    </Button>
  </Nav.Item>
);

export default NavButton;

import React from 'react';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { connect } from 'react-redux';
import { navigate } from 'gatsby';

import { logout } from '../../../lib/api';
import { getSessionId } from '../../../lib/storage';
import { userLoggedOut } from '../../../redux/actions';

import ProfileHead from "./ProfileHead";

const LoggedInNavItem = ({ actionLoggedOut }) => {
  const handleLogout = async () => {
    await logout(getSessionId());
    actionLoggedOut();

    await navigate('/');
  };
  return (
    <NavDropdown title={
      <ProfileHead big />
    } id="basic-nav-dropdown">
      <NavDropdown.Item onSelect={() => navigate('/profile')}>Hantera din profil</NavDropdown.Item>
      <NavDropdown.Divider />
      <NavDropdown.Item onSelect={() => handleLogout()}>Logga ut</NavDropdown.Item>
    </NavDropdown>
  );
};

export default connect(null, {
  actionLoggedOut: userLoggedOut,
})(LoggedInNavItem);

export const getSessionId = () => {
  try {
    return window.localStorage.getItem('sessionId');
  } catch (ex) {
    console.error(ex);
  }
};

export const setSessionId = (sessionId) => {
  try {
    window.localStorage.setItem('sessionId', sessionId);
  } catch (ex) {
    console.error(ex);
  }
};

export const removeSessionId = () => {
  try {
    window.localStorage.removeItem('sessionId');
  } catch (ex) {
    console.error(ex);
  }
};
